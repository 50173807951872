<template>
  <div class="container-fluid">
    <div class="card">
      <div class="card-body">
        <!-- OPCIONES Y RESUMENES DE BOLETAS-PAGO DE CONSEJERAS -->
        <div class="row align-items-ends" v-if="viewFlag == 1">
          <!-- OPCIONES DE NAVEGACION ENTRE MANIFIESTOS Y BOLETAS -->
          <div class="col-2">
            <div class="btn-group btn-group-toggle" data-toggle="buttons">
              <label
                :class="[
                  viewFlag == 2 ? 'active' : '',
                  'btn btn-liquidacion-radio btn-primary',
                ]"
              >
                <input
                  type="radio"
                  name="options"
                  id="option1"
                  checked
                  @click="viewFlag = 2"
                />
                Resumen por manifiesto
              </label>
              <label
                :class="[
                  viewFlag == 1 ? 'active' : '',
                  'btn btn-liquidacion-radio btn-primary',
                ]"
              >
                <input
                  type="radio"
                  name="options"
                  id="option2"
                  @click="viewFlag = 1"
                />
                Pagos de consejeras
              </label>
            </div>
          </div>
          <!-- FIN --- OPCIONES DE NAVEGACION ENTRE MANIFIESTOS Y BOLETAS -->
          <!-- FILTROS DE BUSQUEDA PARA BOLETAS DE CONSEJERA -->
          <div class="col-10 text-right">
            <!-- FILTROS DE PAGO - BOLETA NO COINCIDE, NO VERIFICADAS, CON SALDO DEUDRO, CON SALDO A FAVOR -->
            <input
              class="ml-2 mr-1"
              type="checkbox"
              id="pagos_codigo_consejera_no_coincide"
              value="pagos_codigo_consejera_no_coincide"
              v-model="pagos_codigo_consejera_no_coincide"
            />
            <label
              clas="btn-label-filtros"
              for="pagos_codigo_consejera_no_coincide"
              title="Boletas en las que no coincide el código de consejera"
              >Codigo de consejera no coincide:
              <span class="text-danger">{{
                no_boletas_no_coinciden
              }}</span></label
            >

            <span class="mdi mdi-24px text-danger mdi-map-marker-alert"></span>
            /
            <input
              class="ml-2 mr-1"
              type="checkbox"
              id="pagos_pendientes_de_validar"
              value="pagos_pendientes_de_validar"
              v-model="pagos_pendientes_de_validar"
            />
            <label
              clas="btn-label-filtros"
              for="pagos_pendientes_de_validar"
              title="Boletas pendientes de verificar"
            >
              Sin verificar:
              <span class="text-danger">{{
                no_boletas_sin_verificar
              }}</span></label
            >
            <span class="mdi mdi-24px text-warning mdi-map-marker-alert"></span>
            /
            <input
              class="ml-2 mr-1"
              type="checkbox"
              id="pedidos_saldo_a_favor"
              value="pedidos_saldo_a_favor"
              v-model="pedidos_saldo_a_favor"
            />
            <label
              clas="btn-label-filtros"
              for="pedidos_saldo_a_favor"
              title="Mostrar solo pagos con saldo a favor"
              >Con saldo a favor</label
            >
            /
            <input
              class="ml-2 mr-1"
              type="checkbox"
              id="pedidos_saldo_deudor"
              value="pedidos_saldo_deudor"
              v-model="pedidos_saldo_deudor"
            />
            <label
              clas="btn-label-filtros"
              for="pedidos_saldo_deudor"
              title="Mostrar solo pagos saldo deudor"
              >Con saldo deudor</label
            >
            /
            <input
              class="ml-2 mr-1"
              type="checkbox"
              id="pedidos_monto_complemento"
              value="pedidos_monto_complemento"
              v-model="pedidos_monto_complemento"
            />
            <label
              clas="btn-label-filtros"
              for="pedidos_monto_complemento"
              title="Mostrar solo pagos con monto complemento"
              >Con complemento</label
            >

            <!-- FIN --- FILTROS DE PAGO - BOLETA NO COINCIDE, NO VERIFICADAS, CON SALDO DEUDRO, CON SALDO A FAVOR -->
          </div>
          <!-- FIN FILTROS DE BUSQUEDA PARA BOLETAS DE CONSEJERA -->
          <!-- RESUMEN POR MANIFIESTO FILTRADO -->
          <div class="col-6 text-left justify-content-center align-self-center">
            <div class="card mb-3" style="max-width: 35rem">
              <div class="card-body">
                <h5 class="card-title" v-if="!resumenManifiesto">
                  Total de pagos: {{ optionsVTable.view_total_de_pagos }}
                </h5>
                <div class="card-text" v-if="resumenManifiesto">
                  <h4>
                    Resumen pagos recolectados del manifiesto
                    {{ resumenManifiesto.id }}
                  </h4>

                  <table class="table">
                    <thead>
                      <tr>
                        <td style="width: 292px">Método de pago</td>
                        <td>Pagos</td>
                        <td>Monto (Q.)</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Pedidos con devolución:</td>
                        <td>
                          {{
                            resumenManifiesto.cobros_total_pedidos_devoluciones
                          }}
                        </td>
                        <td>
                          {{
                            resumenManifiesto.cobros_total_monto_en_devoluciones.toFixed(
                              2
                            )
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td>Pagos en efectivo:</td>
                        <td>
                          {{ resumenManifiesto.cobros_total_pedidos_efectivo }}
                        </td>
                        <td>
                          {{
                            resumenManifiesto.cobros_total_monto_en_efectivo.toFixed(
                              2
                            )
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td>Pagos en depósitos:</td>
                        <td>
                          {{ resumenManifiesto.cobros_total_pedidos_depositos }}
                        </td>
                        <td>
                          {{
                            resumenManifiesto.cobros_total_monto_en_depositos.toFixed(
                              2
                            )
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td>Pagos en transferencia:</td>
                        <td>
                          {{
                            resumenManifiesto.cobros_total_pedidos_transferencias
                          }}
                        </td>
                        <td>
                          {{
                            resumenManifiesto.cobros_total_monto_en_transferencias.toFixed(
                              2
                            )
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td>Pagos en VisaNet:</td>
                        <td>
                          {{ resumenManifiesto.cobros_total_pedidos_visanet }}
                        </td>
                        <td>
                          {{
                            resumenManifiesto.cobros_total_monto_en_visanet.toFixed(
                              2
                            )
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td>Pagos en Tigo Pay:</td>
                        <td>
                          {{ resumenManifiesto.cobros_total_pedidos_tigopay }}
                        </td>
                        <td>
                          {{
                            resumenManifiesto.cobros_total_monto_en_tigopay.toFixed(
                              2
                            )
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td>Pagos con otros:</td>
                        <td>
                          {{ resumenManifiesto.cobros_total_pedidos_otros }}
                        </td>
                        <td>
                          {{
                            resumenManifiesto.cobros_total_monto_en_otros.toFixed(
                              2
                            )
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td>Monto total pagos:</td>
                        <td>{{ resumenManifiesto.cobros_total_pedidos }}</td>
                        <td>
                          Q.{{
                            resumenManifiesto.cobros_total_monto.toFixed(2)
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td>Monto total del manifiesto:</td>
                        <td>
                          {{
                            resumenManifiesto.cobros_total_pedidos_y_devoluciones
                          }}
                        </td>
                        <td>
                          Q.{{
                            resumenManifiesto.COBROS_TOTAL_MANIFIESTO.toFixed(2)
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <!-- FIN --- RESUMEN POR MANIFIESTO FILTRADO -->
          <!-- SELECT DE CAMPAÑA -->
          <div class="col-4">
            <label for=""
              >Consultando pedidos de la campaña: {{ CAMPAING_ID }}</label
            >
            <select class="form-control" v-model="CAMPAING_ID" name="campania">
              <option
                v-for="(item, index) in campanias"
                :selected="index == 0"
                :key="index"
                :value="item.campania"
              >
                Campaña {{ item.campania }}
              </option>
            </select>
          </div>
          <!-- FIN --- SELECT DE CAMPAÑA -->
          <!-- DESCARGA DE EXCEL -->
          <div class="col-2">
            <!-- /** GENERAR EXCEL  boletas */ -->
            <download-excel
              class="mt-4"
              style="cursor: pointer"
              :fields="columnasEXCLsectorPedidos"
              :before-generate="
                () => {
                  return startDownload();
                }
              "
              :fetch="
                () => {
                  return fn_boletasParaDocExcel();
                }
              "
              :type="dataExportType"
              :before-finish="finishDownload"
              :name="`Pagos de consejeras campaña ${CAMPAING_ID} ${dataExportType}`"
            >
              <button
                title="Descargar tabla de pagos a Excel"
                type="button"
                class="btn-sm btn-outline-success"
              >
                Pagos de consejeras
                <span
                  style="display: inline-block"
                  class="mdi mdi-12px text-success mdi-microsoft-excel"
                ></span>
              </button>
            </download-excel>
            <!-- /** FIN --- GENERAR EXCEL  */ -->
          </div>
          <!-- FIN --- DESCARGA DE EXCEL -->
          <div class="col-12">
            <!-- <div>
              
              <p>
                
              </p>
              <p>
                
              </p>
            </div> -->
          </div>
        </div>
        <!-- FIN --- OPCIONES Y RESUMENES DE BOLETAS-PAGO DE CONSEJERAS -->
        <!-- RESUMEN TOTALES POR MANIFIESTO -->
        <div class="row align-items-ends" v-if="viewFlag == 2">
          <!-- OPCIONES DE NAVEGACION ENTRE MANIFIESTOS Y BOLETAS -->
          <div class="col-3">
            <div class="btn-group btn-group-toggle" data-toggle="buttons">
              <label
                :class="[
                  viewFlag == 2 ? 'active' : '',
                  'btn btn-liquidacion-radio btn-primary',
                ]"
              >
                <input
                  type="radio"
                  name="options"
                  id="option1"
                  checked
                  @click="viewFlag = 2"
                />
                Resumen por manifiesto
              </label>
              <label
                :class="[
                  viewFlag == 1 ? 'active' : '',
                  'btn btn-liquidacion-radio btn-primary',
                ]"
              >
                <input
                  type="radio"
                  name="options"
                  id="option2"
                  @click="viewFlag = 1"
                />
                Pagos de consejeras
              </label>
            </div>
          </div>
          <div class="col-9"></div>
          <!-- FIN --- OPCIONES DE NAVEGACION ENTRE MANIFIESTOS Y BOLETAS -->
          <!-- RESUMEN POR MANIFIESTO FILTRADO -->
          <div class="col-6 text-left justify-content-center align-self-center">
            <div class="card mb-3" style="max-width: 35rem">
              <div class="card-body">
                <h5 class="card-title">
                  Total de manifiestos con cobros al contado:
                  {{ optionsVTable.view_total_manifiestos }}
                </h5>
              </div>
            </div>
          </div>
          <!-- FIN --- RESUMEN POR MANIFIESTO FILTRADO -->
          <!-- SELECT DE CAMPAÑA -->
          <div class="col-4">
            <label for=""
              >Consultando pedidos de la campaña: {{ CAMPAING_ID }}</label
            >
            <select class="form-control" v-model="CAMPAING_ID" name="campania">
              <option
                v-for="(item, index) in campanias"
                :selected="index == 0"
                :key="index"
                :value="item.campania"
              >
                Campaña {{ item.campania }}
              </option>
            </select>
          </div>
          <!-- FIN --- SELECT DE CAMPAÑA -->
          <!-- DESCARGA DE EXCEL -->
          <div class="col-2">
            <!-- /** GENERAR EXCEL  boletasmanifiestos */ -->
            <download-excel
              class="mt-4"
              style="cursor: pointer"
              :fields="columnasEXCLsector"
              :before-generate="
                () => {
                  return startDownload();
                }
              "
              :fetch="
                () => {
                  return fn_manifiestosParaDocExcel();
                }
              "
              :type="dataExportType"
              :before-finish="finishDownload"
              :name="`Totales cobros por manifiesto campaña ${CAMPAING_ID} ${dataExportType}`"
            >
              <button
                title="Descargar información de la tabla en un documento Excel"
                type="button"
                class="btn-sm btn-outline-success"
              >
                Descargar Manifiestos
                <span
                  style="display: inline-block"
                  class="mdi mdi-12px text-success mdi-microsoft-excel"
                ></span>
              </button>
            </download-excel>
            <!-- /** FIN --- GENERAR EXCEL  */ -->
          </div>
          <!-- FIN --- DESCARGA DE EXCEL -->
        </div>
        <!-- FIN --- RESUMEN TOTALES POR MANIFIESTO -->
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <!-- TABLAS DE RESUMENES POR MANIFIESTO Y PAGOS DE CONSEJERAS -->
        <div class="row">
          <div class="col">
            <div class="card-text" v-if="viewFlag == 2">
              <!-- prettier-ignore -->
              <tablaManifiestos
                :CAMPAING_ID="CAMPAING_ID"
                @emit_total_de_manifiestos="fnEmit_total_de_pagos($event)"
                @emit_vert_detalle_pagos_por_manifiesto="fnEmit_ver_detalle_por_manifiesto($event)"
                @emit_change_filtro_id="fnEmit_manifiestosFiltro_id($event)"
                @emit_change_filtro_cobros_manifiesto_estado="fnEmit_manifiestosFiltro_cobros_manifiesto_estado($event)"
              />
            </div>
            <div class="card-text" v-if="viewFlag == 1">
              <!-- prettier-ignore -->
              <tablaBoletas
                :prop_manifiesto="prop_ver_detalle_manifiesto"
                :pagos_codigo_consejera_no_coincide="pagos_codigo_consejera_no_coincide"
                :pagos_pendientes_de_validar="pagos_pendientes_de_validar"
                :pedidos_saldo_a_favor="pedidos_saldo_a_favor"
                :pedidos_saldo_deudor="pedidos_saldo_deudor"
                :pedidos_monto_complemento="pedidos_monto_complemento"
                :CAMPAING_ID="CAMPAING_ID"
                @emit_total_de_pagos = "fnEmit_total_de_pagos($event)"
                @emit_tblFiltro_boletasPorManifiesto = "fnEmit_tblFiltro_boletasPorManifiesto($event)"

                @emit_change_filtro_id="fnEmit_manifiestosFiltro_id2($event)"
                @emit_change_filtro_MANIFEST_DB_ID="fnEmit_manifiestosFiltro_MANIFEST_DB_ID($event)"
                @emit_change_filtro_ERP_DOC="fnEmit_manifiestosFiltro_ERP_DOC($event)"
                @emit_change_filtro_DELIVERY_CLIENT_NAME="fnEmit_manifiestosFiltro_DELIVERY_CLIENT_NAME($event)"
                @emit_change_filtro_documentos="fnEmit_manifiestosFiltro_documentos($event)"
                @emit_change_filtro_PAGO_METODO_DETALLE="fnEmit_manifiestosFiltro_PAGO_METODO_DETALLE($event)"
              />
            </div>
          </div>
        </div>
        <!-- FIN --- TABLAS DE RESUMENES POR MANIFIESTO Y PAGOS DE CONSEJERAS -->
      </div>
    </div>
  </div>
</template>

<script>
import tablaBoletas from "./boletas.vue";
import tablaManifiestos from "./manifiestos.vue";
import { mapActions } from "vuex";
import JsonExcel from "vue-json-excel";

export default {
  components: {
    downloadExcel: JsonExcel,
    tablaBoletas,
    tablaManifiestos,
  },
  computed: {
    no_boletas_no_coinciden() {
      return this.$store.state.conteoPagoBoletasCodConsejeraNoCoinciden;
    },
  },
  data() {
    return {
      no_boletas_sin_verificar: 0,
      // variables utilizadas para visualizar la tabla de resumen por encima de la tabla de pedidos
      // cuando el campo busqueda por id de manifiesto está lleno
      resumenManifiesto: null,
      prop_ver_detalle_manifiesto: null,
      /**filtros */
      tblFiltrosManifiestos: {
        id: "",
        cobros_manifiesto_estado: "",
      },
      tblFiltrosPagoConsejeras: {
        id: "",
        MANIFEST_DB_ID: "",
        ERP_DOC: "",
        DELIVERY_CLIENT_NAME: "",
        documentos: "",
        PAGO_METODO_DETALLE: "",
      },
      CAMPAING_ID: "",
      campanias: [],
      /** */
      pagos_pendientes_de_validar: false,
      pagos_codigo_consejera_no_coincide: false,
      pedidos_saldo_a_favor: false,
      pedidos_saldo_deudor: false,
      pedidos_monto_complemento: false,
      viewFlag: 2,
      //view vars
      //
      optionsVTable: 0,
      tblFiltro_boletasPorManifiesto: null,
      // export excel vars
      dataExportType: "xls",
      // prettier-ignore
      columnasEXCLsector: {
        "Manifiesto":"id",
        "Total de pagos":"cobros_total_pedidos",
        "Total de pagos en efectivo":"cobros_total_pedidos_efectivo",
        "Monto total en efectivo (Q)":"cobros_total_monto_en_efectivo",
        "Total de pagos en depósitos":"cobros_total_pedidos_depositos",
        "Monto total en depósitos (Q)":"cobros_total_monto_en_depositos",
        "Total de pagos en transferencias":"cobros_total_pedidos_transferencias",
        "Monto total en transferencias (Q)":"cobros_total_monto_en_transferencias",
        "Total de pagos con VisaNet":"cobros_total_pedidos_visanet",
        "Monto total pagos con (Q) VisaNet":"cobros_total_monto_en_visanet",
        "Total de pagos con TigoPay":"cobros_total_pedidos_tigopay",
        "Monto total con TigoPay (Q)":"cobros_total_monto_en_tigopay",
        "Total de pagos con otros":"cobros_total_pedidos_otros",
        "Monto total con otros (Q)":"cobros_total_monto_en_otros",
        "Monto total (Q)":"cobros_total_monto",
        "Estado manifiesto":"cobros_manifiesto_estado",
      },
      columnasEXCLsectorPedidos: {
        id: "id",
        Piloto: "DELIVERY_DRIVER_NAME",
        Placa: "DELIVERY_VEHICULE_PLATES",
        Campaña: "CAMPAING_ID",
        Pedido: "ERP_DOC",
        Consejera: "DELIVERY_CLIENT_NAME",
        "Cod. Consejera": "DELIVERY_CLIENT_CODE",
        Manifiesto: "MANIFEST_DB_ID",
        "Metodo de pago": "PAGO_METODO_DETALLE",
        "Costo del pedido": "PAGO_DUE_TO_PAY",
        "Saldo de consejera": "PAGO_DELIVERY_CLIENT_SALDO",
        "Monto a pagar": "PAGO_MONTO_A_PAGAR",
        "Pago realizado": "PAGO_REALIZADO",
        "pago complemento": "PAGO_COMPLEMENTO_MONTO",
        "Pago monto total": "PAGO_MONTO_TOTAL",
        "Banco-Cuenta-Autorizacion": "documentos",
      },
    };
  },
  watch: {
    CAMPAING_ID(newValue) {
      this.fnActualizarPagosSinVerificarYconCodigoBoletaNoCoincide();
    },
  },
  methods: {
    /**
     * Función que nos permite saber cuanto fue el monto realizado con el documento
     * este si hubiera un "monto complemento" durante el pago
     *
     * @param {*} monto_total == es el monto de dinero pagado durante la entrega
     * @param {*} monto_complemento  == efectivo utilizado para complementar el pago
     */
    fnCalculoPagoRealizadoEnDocumento(monto_total, monto_complemento) {
      let pago_en_documento = monto_total - monto_complemento;
      return pago_en_documento.toFixed(2);
    },

    /**
     * Filtro de tabla que busca ver todos los pagos que aún no han sido verificados
     */
    fnActualizarPagosSinVerificarYconCodigoBoletaNoCoincide() {
      let url = `${process.env.VUE_APP_API_URL}/pago-de-pedidos?PAGO_VERIFICACION_STATUS=0`;
      this.$http
        .get(url, {
          params: { CAMPAING_ID: this.CAMPAING_ID },
          headers: {
            Authorization:
              "Bearer  " + window.localStorage.getItem("feathers-jwt"),
          },
        })
        .then((r) => {
          this.no_boletas_sin_verificar = r.data.total;
        });
      /** cargar boletas que no co inciden */
      let url2 = `${process.env.VUE_APP_API_URL}/pago-de-pedidos?pagos_codigo_consejera_no_coincide=0&$limit=4&PAGO_VERIFICACION_STATUS=0&CAMPAING_ID=${this.CAMPAING_ID}`;
      this.$http
        .get(url2, {
          params: {},
          headers: {
            Authorization:
              "Bearer  " + window.localStorage.getItem("feathers-jwt"),
          },
        })
        .then((r) => {
          console.log(r.data.data);
          this.$store.state.conteoPagoBoletasCodConsejeraNoCoinciden =
            r.data.total;
          this.$store.state.arregloPagoBoletasCodConsejeraNoCoinciden =
            r.data.data;
        });
    },
    /** Consulta pago de manifiestos para generar archivo excel */
    ...mapActions("PagoDeManifiestos", {
      findPagoDeManifiestos: "find",
    }),
    ...mapActions("PagoDePedidos", {
      findPagoDePedidos: "find",
    }),
    ...mapActions("Manifiestos", {
      findManifiestos: "find",
      getManifiesto: "get",
    }),
    getParams() {
      const params = {};
      params["CAMPAING_ID"] = this.CAMPAING_ID;
      params["$sort"] = { createdAt: -1 };

      /** FILTROS TABLA MANIFIESTOS */
      /** GENERAR POR ID */
      if (this.tblFiltrosManifiestos.id != "") {
        params["id"] = this.tblFiltrosManifiestos.id;
      }
      /** GENERAR POR ESTADO DE MANIFIESTO */
      if (this.tblFiltrosManifiestos.cobros_manifiesto_estado != "") {
        params["cobros_manifiesto_estado"] = {
          $like:
            "%" + this.tblFiltrosManifiestos.cobros_manifiesto_estado + "%",
        };
      } else {
        params["cobros_manifiesto_estado"] = { $nin: ["Abierto"] };
      }

      return params;
    },
    getParamsPedidos() {
      /** */
      const params = {};
      params["CAMPAING_ID"] = this.CAMPAING_ID;
      params["$sort"] = { createdAt: -1 };
      /** FILTROS TABLA   PAGOS DE CONSEJERA */
      /** GENERAR POR ID */
      if (this.tblFiltrosPagoConsejeras.id != "") {
        params["id"] = this.tblFiltrosPagoConsejeras.id;
      }
      /** GENERAR POR ESTADO DE MANIFIESTO */
      if (this.tblFiltrosPagoConsejeras.MANIFEST_DB_ID != "") {
        params["MANIFEST_DB_ID"] = {
          $like: "%" + this.tblFiltrosPagoConsejeras.MANIFEST_DB_ID + "%",
        };
      }
      if (this.tblFiltrosPagoConsejeras.ERP_DOC != "") {
        params["ERP_DOC"] = {
          $like: "%" + this.tblFiltrosPagoConsejeras.ERP_DOC + "%",
        };
      }
      if (this.tblFiltrosPagoConsejeras.DELIVERY_CLIENT_NAME != "") {
        params["DELIVERY_CLIENT_NAME"] = {
          $like: "%" + this.tblFiltrosPagoConsejeras.DELIVERY_CLIENT_NAME + "%",
        };
      }
      if (this.tblFiltrosPagoConsejeras.documentos != "") {
        console.log("generando registros excel en base a filtro de documentos");
        params["documentos"] = this.tblFiltrosPagoConsejeras.documentos;
      }
      if (this.tblFiltrosPagoConsejeras.PAGO_METODO_DETALLE != "") {
        params["PAGO_METODO_DETALLE"] = {
          $like: "%" + this.tblFiltrosPagoConsejeras.PAGO_METODO_DETALLE + "%",
        };
      }
      return params;
    },
    // consular campañas existentes
    ...mapActions("Campanias", {
      findCampanias: "find",
    }),
    fnSelecionarCampania() {
      this.CAMPAING_ID = event.target.value;
    },

    // para mandar a traer datos de excel
    ...mapActions("Usuarios", {
      findUsuarios: "find",
    }),
    // funciones excel
    startDownload() {},
    finishDownload() {},

    getDate(date) {
      return date ? moment(date).format("DD-MM-YYYY hh:mm a") : "";
    },
    async fn_manifiestosParaDocExcel() {
      const bultos = await this.findManifiestos({
        query: this.getParams(),
      }).then((result) => {
        // prettier-ignore
        result.data.forEach((registro) => {
          registro.updatedAt = this.getDate(registro.updatedAt);
          registro.createdAt = this.getDate(registro.createdAt);
          registro.cobros_total_monto_en_efectivo = registro.cobros_total_monto_en_efectivo.toFixed(2);
          registro.cobros_total_monto_en_depositos = registro.cobros_total_monto_en_depositos.toFixed(2);
          registro.cobros_total_monto_en_transferencias = registro.cobros_total_monto_en_transferencias.toFixed(2);
          registro.cobros_total_monto_en_visanet = registro.cobros_total_monto_en_visanet.toFixed(2);
          registro.cobros_total_monto_en_tigopay = registro.cobros_total_monto_en_tigopay.toFixed(2);
          registro.cobros_total_monto_en_otros = registro.cobros_total_monto_en_otros.toFixed(2);
          registro.cobros_total_monto = registro.cobros_total_monto.toFixed(2);
        });

        return result.data;
      });

      return bultos;
    },
    async fn_boletasParaDocExcel() {
      const bultos = await this.findPagoDePedidos({
        query: this.getParamsPedidos(),
      }).then((result) => {
        result.data.forEach((registro) => {
          registro.updatedAt = this.getDate(registro.updatedAt);
          registro.createdAt = this.getDate(registro.createdAt);

          /** dar formato a los comprobantes de pago para excel */
          let arregloDeDocumentos = [];
          // prettier-ignore
          for (let index = 0; index < registro.documentos.length; index++) {
            let banco = ""
            let cuenta = ""
            let autorizacion = ""


            /** RESUMEN EXCEL BANCOS */
            if (registro.documentos[index].PAGO_BOLETA_BANCO == null) {
              banco = "N/A"
            }else{
              banco = registro.documentos[index].PAGO_BOLETA_BANCO
            }

            /** RESUMEN EXCEL NO DE CUENTA */

            if (registro.documentos[index].PAGO_BOLETA_CUENTA == null) {
              cuenta = "N/A"
            }else{
              cuenta = registro.documentos[index].PAGO_BOLETA_CUENTA
            }

            /** RESUMEN EXCEL NO. DE AUTORIZACIÓN */
            if (registro.documentos[index].PAGO_BOLETA_NUMERO_AUTORIZACION == null) {
              autorizacion = "N/A"
            }else{
              autorizacion = registro.documentos[index].PAGO_BOLETA_NUMERO_AUTORIZACION
            }

            const element = banco+"-"+cuenta+"-"+autorizacion;
            arregloDeDocumentos.push(element)
          }

          registro.documentos = String(arregloDeDocumentos);

          /** dar formato a la informacion de pagos con complemento */
          registro.PAGO_REALIZADO = this.fnCalculoPagoRealizadoEnDocumento(
            registro.PAGO_MONTO_TOTAL,
            registro.PAGO_COMPLEMENTO_MONTO
          );
        });

        return result.data;
      });

      return bultos;
    },

    // CAPTURA REGISTRSO tabla de pagos de manifiestos
    fnEmit_total_de_pagos(event) {
      console.log("evento", event);
      this.optionsVTable = event;
    },
    /**
     *
     * @param {manifiesto} manifiesto contiene toda la información
     * del manifiesto delc ual se desea ver su detalle en pagos
     */
    fnEmit_ver_detalle_por_manifiesto(event) {
      console.log(event);
      console.log("mostrar detalle por manifiesto");
      this.viewFlag = 1;
      let vm = this;
      setTimeout(() => {
        vm.prop_ver_detalle_manifiesto = event;
      }, 80);
    },
    fnEmit_manifiestosFiltro_id(event) {
      this.tblFiltrosManifiestos.id = event;
    },
    fnEmit_manifiestosFiltro_cobros_manifiesto_estado(event) {
      this.tblFiltrosManifiestos.cobros_manifiesto_estado = event;
    },
    // CAPTURA registros de tabla de pagos de consejeras
    fnEmit_tblFiltro_boletasPorManifiesto(event) {
      console.log("evento filtro", event);
      this.tblFiltro_boletasPorManifiesto = event;
    },
    fnEmit_manifiestosFiltro_id2(event) {
      this.tblFiltrosPagoConsejeras.id = event;
    },
    fnEmit_manifiestosFiltro_MANIFEST_DB_ID(event) {
      this.tblFiltrosPagoConsejeras.MANIFEST_DB_ID = event;
    },
    fnEmit_manifiestosFiltro_ERP_DOC(event) {
      this.tblFiltrosPagoConsejeras.ERP_DOC = event;
    },
    fnEmit_manifiestosFiltro_DELIVERY_CLIENT_NAME(event) {
      this.tblFiltrosPagoConsejeras.DELIVERY_CLIENT_NAME = event;
    },
    fnEmit_manifiestosFiltro_documentos(event) {
      this.tblFiltrosPagoConsejeras.documentos = event;
    },
    fnEmit_manifiestosFiltro_PAGO_METODO_DETALLE(event) {
      this.tblFiltrosPagoConsejeras.PAGO_METODO_DETALLE = event;
    },
  },
  mounted() {
    /* Buscar campañas y seleccionar la mas reciente*/
    this.findCampanias({ query: {} }).then((result) => {
      this.campanias = result;
      this.CAMPAING_ID = this.campanias[0].campania;
    });
    /* actualizar resumen de manifiesto cada vez que la colmuna manifiesto este filtrada en boletas*/
    this.$watch(
      () => {
        // prettier-ignore
        return this.tblFiltrosPagoConsejeras.MANIFEST_DB_ID
      },
      (val) => {
        this.getManifiesto(val)
          .then((result) => {
            this.resumenManifiesto = result;
          })
          .catch(() => {
            this.resumenManifiesto = null;
          });
      }
    );
    //
    this.fnActualizarPagosSinVerificarYconCodigoBoletaNoCoincide();
    const { PagoDePedidos } = this.$FeathersVuex.api;
    PagoDePedidos.on("patched", () => {
      this.fnActualizarPagosSinVerificarYconCodigoBoletaNoCoincide();
    });
  },
};
</script>
<style scoped>
.btn-label-filtros {
  word-wrap: break-all;
}
.btn-liquidacion-radio {
  font-size: 0.6rem !important;
  padding: 8px 21px !important;
  border: 0.5px solid white;
}
.btn-bancos {
  color: #fff;
  background: teal !important;
  border-color: #005a5a;
}

.btn-bancos:hover {
  color: #fff;
  background: #004d4d !important;
  border-color: #009a9a;
}
.btn-bancos:focus,
.btn-bancos.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 90, 90, 0.5);
}
.btn-bancos.disabled,
.btn-bancos:disabled {
  color: #fff;
  background: teal !important;
  border-color: #005a5a;
}
.btn-bancos:not(:disabled):not(.disabled):active,
.btn-bancos:not(:disabled):not(.disabled).active,
.show > .btn-bancos.dropdown-toggle {
  color: #fff;
  background: #00b3b3 !important;
  border-color: #000;
}
.btn-bancos:not(:disabled):not(.disabled):active:focus,
.btn-bancos:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 90, 90, 0.5);
}

.form-clear .material-icons {
  font-size: 16px;
  font-weight: 500;
}
</style>
<style></style>
