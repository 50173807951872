<template>
  <div>
    <v-server-table
      :columns="columns"
      :options="optionsVTable"
      ref="tblListadoDemanifiestos"
    >
      <span slot="updatedAt" slot-scope="props">
        {{ props.row.updatedAt | moment("DD-MM-YYYY HH:mm:ss a") }}
      </span>

      <span
        slot="cobros_total_pedidos_efectivo"
        slot-scope="props"
        :title="`Total de pedidos pagados en efectivo: ${
          props.row.cobros_total_pedidos_efectivo
        }, por un monto de Q.${props.row.cobros_total_monto_en_efectivo.toFixed(
          2
        )}`"
      >
        <strong>{{ props.row.cobros_total_pedidos_efectivo }}</strong>
        /
        <strong
          >Q.{{ props.row.cobros_total_monto_en_efectivo.toFixed(2) }}</strong
        >
      </span>
      <span
        slot="cobros_total_pedidos_depositos"
        slot-scope="props"
        :title="`Total de pedidos pagados en depositos: ${
          props.row.cobros_total_pedidos_depositos
        }, por un monto de Q.${props.row.cobros_total_monto_en_depositos.toFixed(
          2
        )}`"
      >
        <strong>{{ props.row.cobros_total_pedidos_depositos }}</strong>
        /
        <strong
          >Q.{{ props.row.cobros_total_monto_en_depositos.toFixed(2) }}</strong
        >
      </span>
      <span
        slot="cobros_total_pedidos_transferencias"
        slot-scope="props"
        :title="`Total de pedidos pagados en transferencias: ${
          props.row.cobros_total_pedidos_transferencias
        }, por un monto de Q.${props.row.cobros_total_monto_en_transferencias.toFixed(
          2
        )}`"
      >
        <strong>{{ props.row.cobros_total_pedidos_transferencias }}</strong>
        /
        <strong
          >Q.{{
            props.row.cobros_total_monto_en_transferencias.toFixed(2)
          }}</strong
        >
      </span>
      <span
        slot="cobros_total_pedidos_visanet"
        slot-scope="props"
        :title="`Total de pedidos pagados en visanet: ${
          props.row.cobros_total_pedidos_visanet
        }, por un monto de Q.${props.row.cobros_total_monto_en_visanet.toFixed(
          2
        )}`"
      >
        <strong>{{ props.row.cobros_total_pedidos_visanet }}</strong>
        /
        <strong
          >Q.{{ props.row.cobros_total_monto_en_visanet.toFixed(2) }}</strong
        >
      </span>
      <span
        slot="cobros_total_pedidos_tigopay"
        slot-scope="props"
        :title="`Total de pedidos pagados con TigoPay: ${
          props.row.cobros_total_pedidos_tigopay
        }, por un monto de Q.${props.row.cobros_total_monto_en_tigopay.toFixed(
          2
        )}`"
      >
        <strong>{{ props.row.cobros_total_pedidos_tigopay }}</strong>
        /
        <strong
          >Q.{{ props.row.cobros_total_monto_en_tigopay.toFixed(2) }}</strong
        >
      </span>
      <span
        slot="cobros_total_pedidos_otros"
        slot-scope="props"
        :title="`Total de pedidos pagados con otros métodos de pago: ${
          props.row.cobros_total_pedidos_otros
        }, por un monto de Q.${props.row.cobros_total_monto_en_otros.toFixed(
          2
        )}`"
      >
        <strong>{{ props.row.cobros_total_pedidos_otros }}</strong>
        /
        <strong
          >Q.{{ props.row.cobros_total_monto_en_otros.toFixed(2) }}</strong
        >
      </span>
      <span slot="cobros_total_monto" slot-scope="props">
        Q.{{ props.row.cobros_total_monto.toFixed(2) }}
      </span>

      <div slot="opciones" class="text-center" slot-scope="props">
        <button
          @click="fnCargarInformacionDocumentosPagoManifiesto(props.row)"
          class="btn btn-sm btn-link btn-outline-info"
          style="padding: 0 0 0 0 !important"
          data-toggle="modal"
          data-target="#modalCuadre"
          title="Ver cuadre de manifiesto"
        >
          <span
            style="color: #6d6d6d"
            class="mdi mdi-24px mdi-newspaper-variant-multiple-outline"
          ></span>
        </button>

        <button
          class="btn btn-sm btn-link btn-outline-info"
          style="padding: 0 0 0 0 !important"
          :title="`Ver tabla detalle de pagos del manifiesto ${props.row.id}`"
          @click="fnVerDetalleDePagos(props.row)"
        >
          <span class="mdi mdi-24px mdi-clipboard-text-play-outline"></span>
        </button>

        <button
          class="btn btn-sm btn-link btn-outline-info"
          style="padding: 0 0 0 0 !important"
          :title="`Descargar totales por manifiesto ${props.row.id}`"
          @click="fn_consolidado_excel(props.row)"
        >
          <span
            style="color: #1d6f42"
            class="mdi mdi-24px mdi-microsoft-excel"
          ></span>
        </button>
      </div>
    </v-server-table>
    <div
      class="modal fade"
      id="modalCuadre"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalCuadreLabel"
      aria-hidden="true"
    >
      <div
        class="modal-dialog"
        role="document"
        :style="`margin: ${modalMarginFix}rem auto; max-width: 1260px !important`"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5
              v-if="currentManifiesto != null"
              class="modal-title"
              id="modalCuadreLabel"
              :title="`Id del manifiesto: ${currentManifiesto.id}, piloto: ${currentManifiesto.piloto}`"
            >
              Información cuadre de manifiesto
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <!-- /**
              INFORMACIÓN GENERAL DE MANIFIESTO
               */ -->
              <div class="col">
                <div class="card">
                  <div class="card-body shadow">
                    <div class="card-text" v-if="currentManifiesto != null">
                      <h4>
                        Resumen pagos del manifiesto
                        {{ currentManifiesto.id }}
                      </h4>

                      <table class="table">
                        <thead>
                          <tr>
                            <td style="width: 292px">Método de pago</td>
                            <td>Pagos</td>
                            <td>Monto (Q.)</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Pedidos con devolución:</td>
                            <td>
                              {{
                                currentManifiesto.cobros_total_pedidos_devoluciones
                              }}
                            </td>
                            <td>
                              {{
                                currentManifiesto.cobros_total_monto_en_devoluciones.toFixed(
                                  2
                                )
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td>Pagos en efectivo:</td>
                            <td>
                              {{
                                currentManifiesto.cobros_total_pedidos_efectivo
                              }}
                            </td>
                            <td>
                              {{
                                currentManifiesto.cobros_total_monto_en_efectivo.toFixed(
                                  2
                                )
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td>Pagos en depósitos:</td>
                            <td>
                              {{
                                currentManifiesto.cobros_total_pedidos_depositos
                              }}
                            </td>
                            <td>
                              {{
                                currentManifiesto.cobros_total_monto_en_depositos.toFixed(
                                  2
                                )
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td>Pagos en transferencia:</td>
                            <td>
                              {{
                                currentManifiesto.cobros_total_pedidos_transferencias
                              }}
                            </td>
                            <td>
                              {{
                                currentManifiesto.cobros_total_monto_en_transferencias.toFixed(
                                  2
                                )
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td>Pagos en VisaNet:</td>
                            <td>
                              {{
                                currentManifiesto.cobros_total_pedidos_visanet
                              }}
                            </td>
                            <td>
                              {{
                                currentManifiesto.cobros_total_monto_en_visanet.toFixed(
                                  2
                                )
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td>Pagos en Tigo Pay:</td>
                            <td>
                              {{
                                currentManifiesto.cobros_total_pedidos_tigopay
                              }}
                            </td>
                            <td>
                              {{
                                currentManifiesto.cobros_total_monto_en_tigopay.toFixed(
                                  2
                                )
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td>Pagos con otros:</td>
                            <td>
                              {{ currentManifiesto.cobros_total_pedidos_otros }}
                            </td>
                            <td>
                              {{
                                currentManifiesto.cobros_total_monto_en_otros.toFixed(
                                  2
                                )
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td>Monto total pagos:</td>
                            <td>
                              {{ currentManifiesto.cobros_total_pedidos }}
                            </td>
                            <td>
                              Q.{{
                                currentManifiesto.cobros_total_monto.toFixed(2)
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td>Monto total del manifiesto:</td>
                            <td>
                              {{
                                currentManifiesto.cobros_total_pedidos_y_devoluciones
                              }}
                            </td>
                            <td>
                              Q.{{
                                currentManifiesto.COBROS_TOTAL_MANIFIESTO.toFixed(
                                  2
                                )
                              }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-body shadow">
                    <div class="card-text" v-if="documentos.length >= 1">
                      <table class="table">
                        <thead>
                          <tr>
                            <td colspan="2">
                              Resumen efectivo que debe depositar el piloto
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Total pagos en efectivo</td>
                            <td>
                              <strong style="font-weight: 700">
                                Q.{{
                                  currentManifiesto.cobros_total_monto_en_efectivo.toFixed(
                                    2
                                  )
                                }}
                              </strong>
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 240px">
                              (-) Monto total depositado por el piloto
                            </td>
                            <td>
                              <strong style="font-weight: 700">
                                Q.{{ MONTO_TOTAL_DEPOSITOS.toFixed(2) }}
                              </strong>
                            </td>
                          </tr>
                          <tr>
                            <td>Diferencia:</td>
                            <td>
                              Q.{{
                                MONTO_TOTAL_DEPOSITO_PENDIENTE.replace(
                                  "-0",
                                  "0"
                                )
                              }}
                              <!-- prettier-ignore -->
                              <span 
                                v-if=" MONTO_TOTAL_DEPOSITOS.toFixed(2) >
                                currentManifiesto.cobros_total_monto_en_efectivo.toFixed(2)
                                " >de más.</span
                              >
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /**
              INFORMACION DE DOCUMENTOS DEPOSITO DE PILOTO
              */ -->
              <div class="col col-8">
                <h4>Depósito(s) del pilotos</h4>
                <DepositoPiloto
                  :currentManifiesto="currentManifiesto"
                  :documentos="documentos"
                ></DepositoPiloto>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="w-100">
              <button
                @click="(documentos = []), (currentManifiesto = null)"
                type="button"
                class="btn btn-primary float-right"
                data-dismiss="modal"
              >
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DepositoPiloto from "./DepositoPiloto.vue";
import { mapActions } from "vuex";
export default {
  components: {
    DepositoPiloto,
  },
  props: ["CAMPAING_ID"],
  data() {
    return {
      /** informacion de documentos de pago para manifiesto */
      currentManifiesto: null,
      documentos: [],
      // tabla vars
      columns: [
        "id",
        "total_pedidos",
        "total_devoluciones",
        "cobros_total_pedidos",
        "cobros_total_pedidos_efectivo",
        "cobros_total_pedidos_depositos",
        "cobros_total_pedidos_transferencias",
        "cobros_total_pedidos_visanet",
        "cobros_total_pedidos_tigopay",
        "cobros_total_pedidos_otros",
        "cobros_total_monto",
        "cobros_manifiesto_estado",
        "opciones",
      ],
      optionsVTable: {
        tbl_CAMPAING_ID: this.CAMPAING_ID,
        view_total_manifiestos: 0,
        orderBy: {
          column: "id",
          ascending: false,
        },
        sortIcon: {
          base: "fa",
          is: "fa-sort",
          up: "fa-arrow-up",
          down: "fa-arrow-down",
        },
        filterByColumn: true,
        perPage: 10,
        texts: {
          filter: "Filtrar:",
          filterBy: "Filtrar por {column}",
          count: " ",
          first: "Primero",
          last: "Ultimo",
          filter: "Filtrar:",
          limit: "",
          page: "Pág:",
          noResults: "No se encontraron resultados...",
          loading: "Cargando...",
          defaultOption: "Seleccionar {column}",
          columns: "Columnas",
        },
        dateFormat: "DD-MM-YYYY HH:mm",
        dateColumns: ["updatedAt"],
        datepickerOptions: {
          locale: {
            direction: $("#rtl").is(":checked") ? "rtl" : "ltr",
            format: "DD/MM/YYYY",
            separator: " - ",
            applyLabel: "Aplicar",
            cancelLabel: "Cancelar",
            fromLabel: "Del",
            toLabel: "Al",
            customRangeLabel: "Perosnalizado",
            daysOfWeek: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
            monthNames: [
              "Enero",
              "Febrero",
              "Marzo",
              "Abril",
              "Mayo",
              "Junio",
              "Julio",
              "Agosto",
              "Septiembre",
              "Octubre",
              "Noviembre",
              "Diciembre",
            ],
            firstDay: 1,
          },
          showDropdowns: true,
          autoUpdateInput: true,
        },
        pagination: { chunk: 10, dropdown: false },
        filterable: ["id", "cobros_manifiesto_estado"],
        headings: {
          id: "Manifiesto",
          total_pedidos: "Total de pedidos",
          total_devoluciones: "Tot. devoluciones",
          cobros_total_pedidos: "Total de pagos",
          cobros_total_pedidos_efectivo: "En efectivo/monto",
          cobros_total_pedidos_depositos: "En deposito/monto",
          cobros_total_pedidos_transferencias: "En transferencia/monto",
          cobros_total_pedidos_visanet: "En VisaNet/monto",
          cobros_total_pedidos_tigopay: "En TigoPay/monto",
          cobros_total_pedidos_otros: "En otros/monto",
          cobros_total_monto: "Q. total",
          cobros_manifiesto_estado: "Estado",
          opciones: "Ver boleta(s) deposito piloto",
        },
        cellClasses: {},
        requestAdapter(data) {
          let limit = `"$limit":${this.perPage}`;
          let skip = `"$skip":${(data.page - 1) * this.perPage}`;
          let sort = `"$sort[${data.orderBy}]":${data.ascending}`;
          let search = ``;

          if (data.query) {
            if (data.query["id"]) {
              search += `, "id": "${data.query["id"]}"`;
            }
            if (data.query["cobros_manifiesto_estado"]) {
              search += `, "cobros_manifiesto_estado[$like]": "%${data.query["cobros_manifiesto_estado"]}%"`;
            }
          }
          if (this.tbl_CAMPAING_ID) {
            search += `, "CAMPAING_ID": ${this.tbl_CAMPAING_ID}`;
          }
          search += `, "cobros_manifiesto_estado[$nin][]": "Abierto"`;

          return JSON.parse(`{${limit}, ${skip}, ${sort} ${search}}`);
        },
        requestFunction(data) {
          let url = `${process.env.VUE_APP_API_URL}/manifiestos`;
          return this.$http.get(url, {
            params: data,
            headers: {
              Authorization:
                "Bearer  " + window.localStorage.getItem("feathers-jwt"),
            },
          });
        },
        responseAdapter(resp) {
          console.log("los manifiestos", resp);
          //asignar total de usuarios consultados
          this.options.view_total_manifiestos = resp.data.total;
          //asignar total de dispositivos con GPS desconectado

          return {
            data: resp.data.data,
            count: resp.data.total,
          };
        },
        rowClassCallback(row) {}, //Using Boostrap4
      },
    };
  },
  watch: {
    CAMPAING_ID(newValue) {
      this.optionsVTable.tbl_CAMPAING_ID = newValue;
      this.$refs.tblListadoDemanifiestos.refresh();
    },
    optionsVTable: {
      handler(val) {
        this.$emit("emit_total_de_manifiestos", val);
      },
      deep: true,
    },
  },
  computed: {
    modalMarginFix() {
      if (this.documentos.length >= 3) {
        return -14.25;
      }
    },
    VUE_APP_API_URL() {
      return process.env.VUE_APP_API_URL + "/";
    },
    MONTO_TOTAL_DEPOSITOS() {
      if (this.documentos.length >= 1) {
        const sum = this.documentos.reduce((accumulator, object) => {
          return accumulator + object.PAGO_BOLETA_MONTO;
        }, 0);

        return sum;
      } else {
        return 0;
      }
    },
    MONTO_TOTAL_DEPOSITO_PENDIENTE() {
      let resultado = 0;

      if (this.currentManifiesto) {
        resultado =
          this.MONTO_TOTAL_DEPOSITOS.toFixed(2) -
          this.currentManifiesto.cobros_total_monto_en_efectivo.toFixed(2);
      }

      return resultado.toFixed(2);
    },
  },
  methods: {
    /**
     * Función que nos permite saber cuanto fue el monto realizado con el documento
     * este si hubiera un "monto complemento" durante el pago
     *
     * @param {*} monto_total == es el monto de dinero pagado durante la entrega
     * @param {*} monto_complemento  == efectivo utilizado para complementar el pago
     */
    fnCalculoPagoRealizadoEnDocumento(monto_total, monto_complemento) {
      let pago_en_documento = monto_total - monto_complemento;
      return pago_en_documento.toFixed(2);
    },
    /** Utilizado en la elaboracion del detalle de pagos para cuadre de excel */
    ...mapActions("PagoDePedidos", {
      getPagoDePedido: "get",
      findPagoDePedidos: "find",
      patchPagoDePedidos: "patch",
      crearPagoDePedido: "create",
    }),
    ...mapActions("Pedidos", {
      findPedidos: "find",
    }),
    /**
     * fucnion para generar consolidado de manifiesto en documento de excel.
     */
    async fn_consolidado_excel(manifiesto) {
      /**previa para datos de resumen */
      this.findPagoDeManifiestos({
        query: {
          MANIFEST_DB_ID: manifiesto.id,
        },
      }).then((result) => {
        this.documentos = result.data;
        this.currentManifiesto = manifiesto;
      });
      /** fin de la previa, para cargar datos de resumen */
      let xlsx = require("json-as-xlsx");

      const pagoDePedidosParaExcel = await this.findPagoDePedidos({
        query: {
          $limit: manifiesto.cobros_total_pedidos,
          MANIFEST_DB_ID: manifiesto.id,
        },
      }).then((result) => {
        result.data.forEach((documento) => {
          console.log("pagos en boleta", documento);
          /** dar formato a la informacion de pagos con complemento */
          documento.PAGO_REALIZADO = parseFloat(
            this.fnCalculoPagoRealizadoEnDocumento(
              documento.PAGO_MONTO_TOTAL,
              documento.PAGO_COMPLEMENTO_MONTO
            )
          );

          console.log(typeof documento.PAGO_REALIZADO);
          /** dar formato a los comprobantes de pago para excel */
          let arregloDeDocumentos = [];
          // prettier-ignore
          for (let index = 0; index < documento.documentos.length; index++) {
            let banco = ""
            let cuenta = ""
            let autorizacion = ""


            /** RESUMEN EXCEL BANCOS */
            if (documento.documentos[index].PAGO_BOLETA_BANCO == null) {
              banco = "N/A"
            }else{
              banco = documento.documentos[index].PAGO_BOLETA_BANCO
            }

            /** RESUMEN EXCEL NO DE CUENTA */

            if (documento.documentos[index].PAGO_BOLETA_CUENTA == null) {
              cuenta = "N/A"
            }else{
              cuenta = documento.documentos[index].PAGO_BOLETA_CUENTA
            }

            /** RESUMEN EXCEL NO. DE AUTORIZACIÓN */
            if (documento.documentos[index].PAGO_BOLETA_NUMERO_AUTORIZACION == null) {
              autorizacion = "N/A"
            }else{
              autorizacion = documento.documentos[index].PAGO_BOLETA_NUMERO_AUTORIZACION
            }

            const element = banco+"-"+cuenta+"-"+autorizacion;
            arregloDeDocumentos.push(element)
          }

          documento.documentos = String(arregloDeDocumentos);
        });
        console.log("pagos para adjuntar en consolidado de manifiesto", result);
        return result;
      });

      const pagosDevolucion = await this.findPedidos({
        query: {
          $limit: manifiesto.total_pedidos,
          MANIFEST_DB_ID: manifiesto.id,
          STATUS: 3,
        },
      }).then((r) => {
        console.log("los devolucionados response", r);
        return r;
      });

      let data = [
        {
          sheet: "Resumen de manifiesto",
          columns: [
            { label: "Datos del manifiesto", value: "label" }, // Top level data
            { label: "", value: "data" },
            { label: " ", value: "monto" },
            { label: "  ", value: "data4" },
            // Column format
            // { label: "Manifiesto", value: "user" }, // Top level data
            // { label: "Piloto", value: "user" }, // Top level data
            // { label: "Ruta", value: (row) => row.age + " years" }, // Custom format
            // { label: "Empresa", value: (row) => (row.more ? row.more.phone || "" : ""),}, // Run functions
          ],
          content: [
            { label: "Manifiesto", data: manifiesto.id, monto: "", data4: "" },
            {
              label: "Piloto y placa",
              data: manifiesto.piloto,
              monto: "",
              data4: "",
            },
            {
              label: "Ruta(s)",
              data: manifiesto.ROUTE_ID,
              monto: "",
              data4: "",
            },
            {
              label: "Empresa",
              data: manifiesto.piloto_empresa,
              monto: "",
              data4: "",
            },
            { label: "", data: "", monto: "", data4: "" },
            {
              label: "-----Detalle de cobros-----",
              data: "Total pagos",
              monto: "Monto (Q)",
              data4: "",
            },
            {
              label: "Pagos en efectivo",
              data: manifiesto.cobros_total_pedidos_efectivo,
              monto: "Q." + manifiesto.cobros_total_monto_en_efectivo,
              data4: "",
            },
            {
              label: "Pagos en depósitos",
              data: manifiesto.cobros_total_pedidos_depositos,
              monto: "Q." + manifiesto.cobros_total_monto_en_depositos,
              data4: "",
            },
            {
              label: "Pagos en transferencias",
              data: manifiesto.cobros_total_pedidos_transferencias,
              monto: "Q." + manifiesto.cobros_total_monto_en_transferencias,
              data4: "",
            },
            {
              label: "Pagos con VisaNet",
              data: manifiesto.cobros_total_pedidos_visanet,
              monto: "Q." + manifiesto.cobros_total_monto_en_visanet,
              data4: "",
            },
            {
              label: "Pagos con TigoPay",
              data: manifiesto.cobros_total_pedidos_tigopay,
              monto: "Q." + manifiesto.cobros_total_monto_en_tigopay,
              data4: "",
            },

            {
              label: "Pagos con otros",
              data: manifiesto.cobros_total_pedidos_otros,
              monto: "Q." + manifiesto.cobros_total_monto_en_otros,
              data4: "",
            },
            { label: "", data: "", monto: "", data4: "" },
            {
              label: "-----Total de cobros-----",
              data: "",
              monto: "",
              data4: "",
            },
            {
              label: "Total de pagos",
              data: manifiesto.cobros_total_pedidos,
              monto: "",
              data4: "",
            },
            {
              label: "Monto total recolectado",
              data: "Q." + manifiesto.cobros_total_monto.toFixed(2),
              monto: "",
              data4: "",
            },
            { label: "", data: "", monto: "", data4: "" },
            {
              label: "Total pedidos devueltos",
              data:
                "Q." + manifiesto.cobros_total_monto_en_devoluciones.toFixed(2),
              monto: "",
              data4: "",
            },

            {
              label: "-----Total manifiesto-----",
              data: "",
              monto: "",
              data4: "",
            },
            {
              label: "Monto total del manifiesto",
              data: "Q." + manifiesto.COBROS_TOTAL_MANIFIESTO.toFixed(2),
              monto: "",
              data4: "",
            },
            { label: "", data: "", monto: "", data4: "" },
          ],
        },
        {
          sheet: "Detalle de pagos",
          columns: [
            { label: "id", value: "id" },
            { label: "Piloto", value: "DELIVERY_DRIVER_NAME" },
            { label: "Placa", value: "DELIVERY_VEHICULE_PLATES" },
            { label: "Campaña", value: "CAMPAING_ID" },
            { label: "Pedido", value: "ERP_DOC" },
            { label: "Consejera", value: "DELIVERY_CLIENT_NAME" },
            { label: "Cod. Consejera", value: "DELIVERY_CLIENT_CODE" },
            { label: "Manifiesto", value: "MANIFEST_DB_ID" },
            { label: "Metodo de pago", value: "PAGO_METODO_DETALLE" },
            { label: "Costo del pedido", value: "PAGO_DUE_TO_PAY" },
            {
              label: "Saldo de consejera",
              value: "PAGO_DELIVERY_CLIENT_SALDO",
            },
            { label: "Monto a pagar", value: "PAGO_MONTO_A_PAGAR" },
            { label: "Pago realizado", value: "PAGO_REALIZADO" },
            { label: "pago complemento", value: "PAGO_COMPLEMENTO_MONTO" },
            { label: "Pago monto total", value: "PAGO_MONTO_TOTAL" },
            { label: "Banco-Cuenta-Autorizacion", value: "documentos" },
          ],
          content: pagoDePedidosParaExcel.data,
        },
      ];
      /** Detalle de pagos, agregar registros de devolución */
      /**
       *
       * Por cada pedido con devolución que pertenezca al manifiesto
       * agregarlos a data[1].content, arreglo de todos los registros detalle dentro del archivo de excel
       *
       *
       * SALDO_COMPUTADO es la variable que contiene la información del saldo real entre la resta del
       * monto del pedido y
       * saldo envíado por lecleire
       *
       *
       * si es positivo, el saldo es a favor de la consejera
       * si es negativo, el saldo es a favor de LeCleire
       */
      for (let index = 0; index < pagosDevolucion.data.length; index++) {
        console.log(
          "generando cuadre, hay pedidos con devolucion",
          pagosDevolucion.data
        );
        console.log(
          "modificando por devoluciones, array existente: ",
          data[1].content
        );
        // prettier-ignore
        pagosDevolucion.data[index].SALDO_COMPUTADO = pagosDevolucion.data[index].DUE_TO_PAY - pagosDevolucion.data[index].DEVOLUCION_DELIVERY_CLIENT_SALDO;
        // prettier-ignore
        pagosDevolucion.data[index].SALDO_COMPUTADO = pagosDevolucion.data[index].SALDO_COMPUTADO.toFixed(2);

        //prettier-ignore
        data[1].content.push({
          id: "",
          DELIVERY_DRIVER_NAME: pagosDevolucion.data[index].DELIVERY_DRIVER_NAME,
          DELIVERY_VEHICULE_PLATES: pagosDevolucion.data[index].DELIVERY_VEHICULE_PLATES,
          CAMPAING_ID: pagosDevolucion.data[index].CAMPAING_ID,
          ERP_DOC: pagosDevolucion.data[index].ERP_DOC,
          DELIVERY_CLIENT_NAME: pagosDevolucion.data[index].DELIVERY_CLIENT_NAME,
          DELIVERY_CLIENT_CODE: pagosDevolucion.data[index].DELIVERY_CLIENT_CODE,
          MANIFEST_DB_ID: pagosDevolucion.data[index].MANIFEST_DB_ID,
          PAGO_METODO_DETALLE: "Devolución",
          PAGO_DUE_TO_PAY: pagosDevolucion.data[index].DUE_TO_PAY,
          PAGO_DELIVERY_CLIENT_SALDO: parseFloat(pagosDevolucion.data[index].SALDO_COMPUTADO),
          PAGO_MONTO_A_PAGAR: pagosDevolucion.data[index].DEVOLUCION_DELIVERY_CLIENT_SALDO,
          PAGO_REALIZADO: 0,
          PAGO_MONTO_TOTAL: 0,
          documentos: "",
        });
      }

      /** totales sumatoria de columna */
      let tot_PAGO_DUE_TO_PAY = data[1].content.reduce(
        (accumulator, object) => {
          return accumulator + object.PAGO_DUE_TO_PAY;
        },
        0
      );
      let tot_PAGO_DELIVERY_CLIENT_SALDO = data[1].content.reduce(
        (accumulator, object) => {
          return accumulator + object.PAGO_DELIVERY_CLIENT_SALDO;
        },
        0
      );
      let tot_PAGO_MONTO_A_PAGAR = data[1].content.reduce(
        (accumulator, object) => {
          return accumulator + object.PAGO_MONTO_A_PAGAR;
        },
        0
      );
      let tot_PAGO_REALIZADO = data[1].content.reduce((accumulator, object) => {
        return accumulator + object.PAGO_REALIZADO;
      }, 0);
      data[1].content.push({
        id: "",
        DELIVERY_DRIVER_NAME: "",
        DELIVERY_VEHICULE_PLATES: "",
        CAMPAING_ID: "",
        ERP_DOC: "",
        DELIVERY_CLIENT_NAME: "",
        DELIVERY_CLIENT_CODE: "",
        MANIFEST_DB_ID: "",
        PAGO_METODO_DETALLE: "",
        PAGO_DUE_TO_PAY: tot_PAGO_DUE_TO_PAY,
        PAGO_DELIVERY_CLIENT_SALDO: tot_PAGO_DELIVERY_CLIENT_SALDO,
        PAGO_MONTO_A_PAGAR: tot_PAGO_MONTO_A_PAGAR,
        PAGO_REALIZADO: tot_PAGO_REALIZADO,
        PAGO_MONTO_TOTAL: "",
        documentos: "",
      });

      /** agregar documentos por deposito de pilotos al resumen del excel */
      if (this.documentos.length >= 1) {
        data[0].content.push({
          label: "-----Deposito(s) de piloto-----",
          data: "",
          monto: "",
          data4: "",
        });
        data[0].content.push({
          label: "Banco",
          data: "No. de cuenta",
          monto: "No. de autorización",
          data4: "Monto",
        });
      }
      /** agregar documentos por deposito de pilotos al resumen del excel */
      for (let index = 0; index < this.documentos.length; index++) {
        console.log("agregando documento al resumen", this.documentos[index]);
        console.log("al arreglo existente:", data[0].content);
        let banco = "";
        let cuenta = "";
        let autorizacion = "";

        /** RESUMEN EXCEL BANCOS */
        if (this.documentos[index].PAGO_BOLETA_BANCO == null) {
          banco = "N/A";
        } else {
          banco = this.documentos[index].PAGO_BOLETA_BANCO;
        }

        /** RESUMEN EXCEL NO DE CUENTA */

        if (this.documentos[index].PAGO_BOLETA_CUENTA == null) {
          cuenta = "N/A";
        } else {
          cuenta = this.documentos[index].PAGO_BOLETA_CUENTA;
        }

        /** RESUMEN EXCEL NO. DE AUTORIZACIÓN */
        if (this.documentos[index].PAGO_BOLETA_NUMERO_AUTORIZACION == null) {
          autorizacion = "N/A";
        } else {
          autorizacion = this.documentos[index].PAGO_BOLETA_NUMERO_AUTORIZACION;
        }

        data[0].content.push({
          label: banco,
          data: cuenta,
          monto: autorizacion,
          data4: "Q." + this.documentos[index].PAGO_BOLETA_MONTO,
        });
      }

      let settings = {
        fileName: "Consolidado manifiesto ", // Name of the resulting spreadsheet
        extraLength: 3, // A bigger number means that columns will be wider
        writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
      };

      xlsx(data, settings); // Will download the excel file
    },
    ...mapActions("PagoDeManifiestos", {
      getPagoDeManifiesto: "get",
      findPagoDeManifiestos: "find",
      patchPagoDeManifiestos: "patch",
      crearPagoDeManifiesto: "create",
    }),
    /**
     *
     * @param {manifiesto} manifiesto contiene toda la información
     * del manifiesto delc ual se desea ver su detalle en pagos
     */
    fnVerDetalleDePagos(manifiesto) {
      this.$emit("emit_vert_detalle_pagos_por_manifiesto", manifiesto);
    },
    fnCargarInformacionDocumentosPagoManifiesto(manifiesto) {
      this.findPagoDeManifiestos({
        query: {
          MANIFEST_DB_ID: manifiesto.id,
        },
      }).then((result) => {
        this.documentos = result.data;
        this.currentManifiesto = manifiesto;
      });
    },
  },
  mounted() {
    //** variable para filtrado de registros por campaña */
    this.optionsVTable.tbl_CAMPAING_ID = this.CAMPAING_ID;

    /** refrescar la tabla cada vez que se sincronize un pago al servidor */
    const { PagoDePedidos } = this.$FeathersVuex.api;
    PagoDePedidos.on("created", () => {
      if (this.$refs.tblListadoDemanifiestos) {
        this.$refs.tblListadoDemanifiestos.refresh();
      }

      /** actualizacion de notificaciones */
      let url = `${process.env.VUE_APP_API_URL}/pago-de-pedidos?pagos_codigo_consejera_no_coincide=0&$limit=4&PAGO_VERIFICACION_STATUS=0`;
      return this.$http
        .get(url, {
          params: {},
          headers: {
            Authorization:
              "Bearer  " + window.localStorage.getItem("feathers-jwt"),
          },
        })
        .then((r) => {
          console.log(r.data.data);
          this.$store.state.conteoPagoBoletasCodConsejeraNoCoinciden =
            r.data.total;
          this.$store.state.arregloPagoBoletasCodConsejeraNoCoinciden =
            r.data.data;
        });
    });

    /** actualizar verficiados y boletas que no coinciden cada vez que se genere un paogo */

    PagoDePedidos.on("patched", () => {
      if (this.$refs.tblListadoDemanifiestos) {
        this.$refs.tblListadoDemanifiestos.refresh();
      }
      let url = `${process.env.VUE_APP_API_URL}/pago-de-pedidos?pagos_codigo_consejera_no_coincide=0&$limit=4&PAGO_VERIFICACION_STATUS=0`;
      return this.$http
        .get(url, {
          params: {},
          headers: {
            Authorization:
              "Bearer  " + window.localStorage.getItem("feathers-jwt"),
          },
        })
        .then((r) => {
          console.log(r.data.data);
          this.$store.state.conteoPagoBoletasCodConsejeraNoCoinciden =
            r.data.total;
          this.$store.state.arregloPagoBoletasCodConsejeraNoCoinciden =
            r.data.data;
        });
    });

    /** escuchar cambios en los filtros de la tabla, para generar mismos registros en el excel */
    this.$watch(
      () => {
        return this.$refs.tblListadoDemanifiestos.query.id;
      },
      (val) => {
        this.$emit("emit_change_filtro_id", val);
      }
    );
    this.$watch(
      () => {
        // prettier-ignore
        return this.$refs.tblListadoDemanifiestos.query.cobros_manifiesto_estado;
      },
      (val) => {
        this.$emit("emit_change_filtro_cobros_manifiesto_estado", val);
      }
    );
  },
};
</script>

<style scoped>
.btn-outline-info {
  color: #031d6a !important;
  background-color: transparent !important;
  background-image: none !important;
  border-color: #031d6a !important;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #031d6a;
  /* border: 1px solid #031D6A; */
}
.btn-outline-info:focus {
  color: #fff !important;
  background-color: #031d6a !important;
  border-color: #031d6a !important;
}

.btn-outline-info:not(:disabled):not(.disabled).active,
.btn-outline-info:not(:disabled):not(.disabled):active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff !important;
  background-color: #031d6a !important;
  border-color: #031d6a !important;
}
</style>
<style>
.VueTables__limit {
  display: none;
}
thead th {
  font-size: 8px !important;
  text-align: left;
}
tbody tr td:nth-child(1) {
  width: 80px;
}
</style>
