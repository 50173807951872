<template>
  <div>
    <div class="card">
      <div class="card-body shadow">
        <div class="card-text">
          <!-- INICIO DE carousel-->
          <carousel
            ref="CaruselPagoPiloto"
            :per-page="1"
            paginationActiveColor="#000000"
            paginationColor="#000000"
          >
            <slide v-for="(item, index) in documentos" :key="index">
              <div class="row">
                <!-- imagen de documentos respaldo pago -->
                <div class="col col-5">
                  <zoom-on-hover
                    v-if="item.PAGO_BOLETA_IMAGEN"
                    id="fichaUltimaEntregaDELIVERY_IMAGE"
                    :scale="7"
                    :img-normal="VUE_APP_API_URL + item.PAGO_BOLETA_IMAGEN"
                    :img-zoom="VUE_APP_API_URL + item.PAGO_BOLETA_IMAGEN"
                  ></zoom-on-hover>
                </div>
                <!-- fin --- imagen de documentos respaldo pago -->
                <!-- INFORMACION DE PAGO -->
                <div class="col">
                  <div class="row">
                    <div class="col">
                      <p title="Banco">
                        <small>Banco:</small> <br />
                        <strong>{{ item.PAGO_BOLETA_BANCO }}</strong>
                      </p>
                      <p title="Número de cuenta">
                        <small>Número de cuenta:</small> <br />
                        <strong>{{ item.PAGO_BOLETA_CUENTA }}</strong>
                      </p>
                    </div>
                    <div class="col">
                      <p>
                        <small>Número de autorización:</small> <br />
                        <strong>{{
                          item.PAGO_BOLETA_NUMERO_AUTORIZACION
                        }}</strong>
                      </p>
                      <p>
                        <small>Monto:</small><br />
                        <strong
                          >Q{{ item.PAGO_BOLETA_MONTO.toFixed(2) }}</strong
                        >
                      </p>
                    </div>
                  </div>
                </div>
                <!-- FIN --- INFORMACION DE PAGO -->
              </div>
            </slide>
          </carousel>
          <!-- /** FIN carousel*/ -->
          <p
            v-if="
              documentos &&
              documentos.length <= 0 &&
              currentManifiesto &&
              currentManifiesto.cobros_total_pedidos_efectivo >= 1
            "
          >
            <small
              >Aún no hay información de deposito(s) realziados por el
              piloto.</small
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Carousel, Slide } from "vue-carousel";
export default {
  data() {
    return {};
  },
  components: {
    Carousel,
    Slide,
  },
  props: {
    documentos: {
      type: Array,
      default: () => {
        return [];
      },
    },
    currentManifiesto: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  watch: {
    documentos(newValue) {
      let vm = this;
      setTimeout(() => {
        console.log(
          "actualizacion para muestra info en vue carousel",
          newValue
        );
        vm.$forceUpdate();
      }, 350);
    },
  },
  computed: {
    VUE_APP_API_URL() {
      return process.env.VUE_APP_API_URL + "/";
    },
  },
};
</script>
