<template>
  <div>
    <!-- dialog documentos del pago -->
    <modalInfoPagoConsejera :pago="pago"></modalInfoPagoConsejera>
    <!-- dialog documentos del pago -->
    <!-- tabla de pagos -->
    <v-server-table
      :columns="columns"
      :options="optionsVTable"
      ref="tblPagoDePedidos"
      id="tblPagoDePedidos"
    >
      <div slot="PAGO_DUE_TO_PAY" slot-scope="props">
        {{ props.row.PAGO_DUE_TO_PAY.toFixed(2) }}
      </div>
      <div slot="PAGO_DELIVERY_CLIENT_SALDO" slot-scope="props">
        {{ props.row.PAGO_DELIVERY_CLIENT_SALDO.toFixed(2) }}
      </div>
      <div slot="PAGO_MONTO_A_PAGAR" slot-scope="props">
        {{ props.row.PAGO_MONTO_A_PAGAR.toFixed(2) }}
      </div>
      <div slot="PAGO_MONTO_TOTAL" slot-scope="props">
        {{ props.row.PAGO_MONTO_TOTAL.toFixed(2) }}
      </div>
      <div slot="PAGO_REALIZADO" slot-scope="props">
        <span>{{
          fnCalculoPagoRealizadoEnDocumento(
            props.row.PAGO_MONTO_TOTAL,
            props.row.PAGO_COMPLEMENTO_MONTO
          )
        }}</span>
      </div>
      <div slot="PAGO_COMPLEMENTO_MONTO" slot-scope="props">
        <span v-if="!props.row.PAGO_COMPLEMENTO_MONTO" style="color: #8b8b8bee"
          >N/A</span
        >
        <span v-else>{{ props.row.PAGO_COMPLEMENTO_MONTO }}</span>
      </div>

      <div slot="PAGO_ESTADO" slot-scope="props">
        <p
          style="word-break: keep-all"
          v-if="props.row.PAGO_MONTO_SALDO_A_FAVOR_FLAG == 1"
        >
          A favor Q {{ props.row.PAGO_MONTO_SALDO_A_FAVOR.toFixed(2) }}
          <span
            style="color: red !important"
            class="mdi mdi-arrow-down-bold"
          ></span>
        </p>
        <p
          style="word-break: keep-all"
          v-if="props.row.PAGO_MONTO_DEUDOR_FLAG == 1"
        >
          Deudor Q {{ props.row.PAGO_MONTO_DEUDOR.toFixed(2) }}
          <span
            style="color: green !important"
            class="mdi mdi-arrow-up-bold"
          ></span>
        </p>
        <p
          style="word-break: keep-all"
          v-if="
            props.row.PAGO_MONTO_DEUDOR_FLAG == null &&
            props.row.PAGO_MONTO_SALDO_A_FAVOR_FLAG == null
          "
        >
          Exacto
        </p>
      </div>
      <!-- prettier-ignore -->
      <div slot="PAGO_VERIFICACION_STATUS" slot-scope="props" class="text-center">

        <p
          v-if="props.row.PAGO_VERIFICACION_STATUS == 0"
          type="button"
          class="btn-sm btn-outline-primary"
          data-toggle="modal"
          data-target="#modalInfoPagoConsejera"
          @click="fnAsignPagoParaModal(props.row)"
          title="Verificar pago"
          
          
        >
          Pago pendiente de verificar
          <span class="mdi mdi-18px mdi-eye-remove-outline" style="color: red;"></span>
        </p>

        <p
          :title="`Verificado por ${props.row.PAGO_VERIFICACION_USUARIO} el ${getDate(props.row.updatedAt)}`"
          v-else
        >
          Verificado
          <span class="mdi mdi-18px mdi-check"></span>
        </p>
      </div>
      <div slot="documentos" slot-scope="props">
        <div v-if="props.row.documentos.length >= 1">
          <p v-for="documento in props.row.documentos" :key="documento.id">
            <span style="font-weight: 700">{{
              documento.PAGO_BOLETA_BANCO
            }}</span>
            <span style="font-weight: 700">
              {{ documento.PAGO_BOLETA_NUMERO_AUTORIZACION }}</span
            >
          </p>
        </div>
        <div v-else>
          <span style="color: #8b8b8bee">N/A</span>
        </div>
      </div>

      <div slot="opciones" class="text-center" slot-scope="props">
        <button
          title="Ver información del pago"
          type="button"
          class="btn-sm btn-outline-primary"
          data-toggle="modal"
          data-target="#modalInfoPagoConsejera"
          @click="fnAsignPagoParaModal(props.row)"
        >
          <span
            title="Ver información del pago"
            class="mdi mdi-11px mdi-newspaper-variant-multiple-outline"
          ></span>
        </button>
      </div>
    </v-server-table>
    <!-- tabla de pagos de consejeras -->
  </div>
</template>
<script>
import modalInfoPagoConsejera from "./boleta.vue";
export default {
  components: {
    modalInfoPagoConsejera,
  },
  props: [
    "prop_manifiesto",
    "pedidos_saldo_deudor",
    "pedidos_monto_complemento",
    "pedidos_saldo_a_favor",
    "pagos_codigo_consejera_no_coincide",
    "pagos_pendientes_de_validar",
    "CAMPAING_ID",
  ],
  data() {
    return {
      pago: null,
      // tabla vars
      columns: [
        "id",
        "MANIFEST_DB_ID",
        "ERP_DOC",
        "DELIVERY_CLIENT_NAME",
        "PAGO_METODO_DETALLE",
        "documentos",
        "PAGO_DUE_TO_PAY",
        "PAGO_DELIVERY_CLIENT_SALDO",
        "PAGO_MONTO_A_PAGAR",
        "PAGO_REALIZADO",
        "PAGO_COMPLEMENTO_MONTO",
        "PAGO_MONTO_TOTAL",
        "PAGO_ESTADO",
        "opciones",
        "PAGO_VERIFICACION_STATUS",
      ],
      optionsVTable: {
        tbl_pedidos_saldo_a_favor: false,
        tbl_pedidos_saldo_deudor: false,
        tbl_pedidos_monto_complemento: false,
        tbl_pagos_codigo_consejera_no_coincide: false,
        tbl_pagos_pendientes_de_validar: false,
        tbl_CAMPAING_ID: this.CAMPAING_ID,
        view_total_de_pagos: 0,
        orderBy: {
          column: "id",
          ascending: false,
        },
        sortIcon: {
          base: "fa",
          is: "fa-sort",
          up: "fa-arrow-up",
          down: "fa-arrow-down",
        },
        filterByColumn: true,
        perPage: 10,
        texts: {
          filter: "Filtrar:",
          filterBy: "Filtrar por {column}",
          count: " ",
          first: "Primero",
          last: "Ultimo",
          filter: "Filtrar:",
          limit: "",
          page: "Pág:",
          noResults: "No se encontraron resultados...",
          loading: "Cargando...",
          defaultOption: "Seleccionar {column}",
          columns: "Columnas",
        },
        dateFormat: "DD-MM-YYYY HH:mm",
        dateColumns: ["updatedAt"],
        datepickerOptions: {
          locale: {
            direction: $("#rtl").is(":checked") ? "rtl" : "ltr",
            format: "DD/MM/YYYY",
            separator: " - ",
            applyLabel: "Aplicar",
            cancelLabel: "Cancelar",
            fromLabel: "Del",
            toLabel: "Al",
            customRangeLabel: "Perosnalizado",
            daysOfWeek: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
            monthNames: [
              "Enero",
              "Febrero",
              "Marzo",
              "Abril",
              "Mayo",
              "Junio",
              "Julio",
              "Agosto",
              "Septiembre",
              "Octubre",
              "Noviembre",
              "Diciembre",
            ],
            firstDay: 1,
          },
          showDropdowns: true,
          autoUpdateInput: true,
        },
        pagination: { chunk: 10, dropdown: false },
        filterable: [
          "id",
          "MANIFEST_DB_ID",
          "ERP_DOC",
          "DELIVERY_CLIENT_NAME",
          "documentos",
          "PAGO_METODO_DETALLE",
        ],
        headings: {
          DELIVERY_CLIENT_NAME: "consejera",
          documentos: "documento no.",
          MANIFEST_DB_ID: "manifiesto",
          ERP_DOC: "pedido",
          PAGO_DUE_TO_PAY: "pedido monto",
          PAGO_DELIVERY_CLIENT_SALDO: "cliente saldo",
          PAGO_MONTO_A_PAGAR: "monto a pagar",
          PAGO_ESTADO: "Pago estado",
          PAGO_METODO_DETALLE: "forma de pago",
          PAGO_REALIZADO: "Pago realizado",
          PAGO_COMPLEMENTO_MONTO: "Pago complemento",
          opciones: "documento(s)",
          PAGO_VERIFICACION_STATUS: "verificación",
        },
        cellClasses: {
          opciones: [
            {
              class: "",
              condition: (row) => row.MANIFEST_DB_ID > 80,
            },
          ],
        },
        requestAdapter(data) {
          let limit = `"$limit":${this.perPage}`;
          let skip = `"$skip":${(data.page - 1) * this.perPage}`;
          let sort = `"$sort[createdAt]":${data.ascending}`;
          let search = ``;
          // search += `, "reportebultosvalidados_id": "${this.idDelReporte}"`;
          if (data.query) {
            /** FILTROS STANDAR TABLA DE PAGOS */
            //
            if (data.query["id"]) {
              search += `, "id": "${data.query["id"]}"`;
            }
            //
            if (data.query["MANIFEST_DB_ID"]) {
              search += `, "MANIFEST_DB_ID": "${data.query["MANIFEST_DB_ID"]}"`;
            }
            //
            if (data.query["ERP_DOC"]) {
              search += `, "ERP_DOC": "${data.query["ERP_DOC"]}"`;
            }
            //
            if (data.query["DELIVERY_CLIENT_NAME"]) {
              search += `, "DELIVERY_CLIENT_NAME[$like]": "%${data.query["DELIVERY_CLIENT_NAME"]}%"`;
            }
            //
            if (data.query["documentos"]) {
              search += `, "documentos": "${data.query["documentos"]}"`;
            }

            //
            if (data.query["PAGO_METODO_DETALLE"]) {
              search += `, "PAGO_METODO_DETALLE[$like]": "%${data.query["PAGO_METODO_DETALLE"]}%"`;
            }
            //
            //

            if (data.query["CREATED"]) {
              search += `, "CREATED[$gte]": "${data.query.CREATED.start}"`;
              search += `, "CREATED[$lte]": "${data.query.CREATED.end}"`;
            }
            if (data.query["fecha_validacion"]) {
              search += `, "fecha_validacion[$gte]": "${data.query.fecha_validacion.start}"`;
              search += `, "fecha_validacion[$lte]": "${data.query.fecha_validacion.end}"`;
            }
          }

          /** FILTROS POR CONDICIÓN / ESTADO DEL PAGO */

          if (this.tbl_pedidos_saldo_a_favor == true) {
            search += `, "PAGO_MONTO_SALDO_A_FAVOR_FLAG": 1`;
          }
          if (this.tbl_pedidos_saldo_deudor == true) {
            search += `, "PAGO_MONTO_DEUDOR_FLAG": 1`;
          }
          if (this.tbl_pedidos_monto_complemento == true) {
            search += `, "PAGO_COMPLEMENTO_FLAG": 1`;
          }

          if (this.tbl_pagos_pendientes_de_validar == true) {
            search += `, "PAGO_VERIFICACION_STATUS": 0`;
          }
          if (this.tbl_CAMPAING_ID) {
            search += `, "CAMPAING_ID": ${this.tbl_CAMPAING_ID}`;
          }
          if (this.tbl_pagos_codigo_consejera_no_coincide == true) {
            search += `, "pagos_codigo_consejera_no_coincide": 0`;
          }

          return JSON.parse(`{${limit}, ${skip}, ${sort} ${search}}`);
        },
        requestFunction(data) {
          let url = `${process.env.VUE_APP_API_URL}/pago-de-pedidos`;
          return this.$http.get(url, {
            params: data,
            headers: {
              Authorization:
                "Bearer  " + window.localStorage.getItem("feathers-jwt"),
            },
          });
        },
        responseAdapter(resp) {
          //asignar total de usuarios consultados
          this.options.view_total_de_pagos = resp.data.total;
          //asignar total de dispositivos con GPS desconectado

          return {
            data: resp.data.data,
            count: resp.data.total,
          };
        },
        rowClassCallback(row) {
          if (row.MANIFEST_DB_ID > 80) {
            // return "bg-danger";
          }
        }, //Using Boostrap4
      },
    };
  },
  computed: {
    VUE_APP_API_URL() {
      return process.env.VUE_APP_API_URL + "/";
    },
  },
  methods: {
    /** función generica para transofrmacion de datos tipo fecha */
    getDate(date) {
      return date ? moment(date).format("DD-MM-YYYY hh:mm a") : "";
    },
    /** Asignación de informacion objeto (pago) para consulta de informacion en dialog
     * @param pago = contiene toda la información del pago a consultar.
     */
    fnAsignPagoParaModal(pago) {
      console.log("asignando informacion de pago: ", pago);
      this.pago = pago;
    },
    /**
     * Función que nos permite saber cuanto fue el monto realizado con el documento
     * este si hubiera un "monto complemento" durante el pago
     *
     * @param {*} monto_total == es el monto de dinero pagado durante la entrega
     * @param {*} monto_complemento  == efectivo utilizado para complementar el pago
     */
    fnCalculoPagoRealizadoEnDocumento(monto_total, monto_complemento) {
      if (monto_complemento == null) {
        return monto_total;
      } else {
        let pago_en_documento = monto_total - monto_complemento;
        return pago_en_documento.toFixed(2);
      }
    },
  },

  watch: {
    /** */
    optionsVTable: {
      handler(val) {
        this.$emit("emit_total_de_pagos", val);
      },
      deep: true,
    },
    pedidos_saldo_a_favor(newValue) {
      this.optionsVTable.tbl_pedidos_saldo_a_favor = newValue;
      this.$refs.tblPagoDePedidos.refresh();
    },
    pedidos_saldo_deudor(newValue) {
      this.optionsVTable.tbl_pedidos_saldo_deudor = newValue;
      this.$refs.tblPagoDePedidos.refresh();
    },
    pedidos_monto_complemento(newValue) {
      this.optionsVTable.tbl_pedidos_monto_complemento = newValue;
      this.$refs.tblPagoDePedidos.refresh();
    },
    pagos_codigo_consejera_no_coincide(newValue) {
      this.optionsVTable.tbl_pagos_codigo_consejera_no_coincide = newValue;
      this.$refs.tblPagoDePedidos.refresh();
    },
    pagos_pendientes_de_validar(newValue) {
      this.optionsVTable.tbl_pagos_pendientes_de_validar = newValue;
      this.$refs.tblPagoDePedidos.refresh();
    },
    CAMPAING_ID(newValue) {
      this.optionsVTable.tbl_CAMPAING_ID = newValue;
      this.$refs.tblPagoDePedidos.refresh();
    },
  },
  mounted() {
    /** filtrar los registros de la tabla según campaña seleccionada en liquidaciones.vue */
    this.optionsVTable.tbl_CAMPAING_ID = this.CAMPAING_ID;
    /** refrescar la tabla cada vez que se sincronize un pago al servidor */
    const { PagoDePedidos } = this.$FeathersVuex.api;
    PagoDePedidos.on("created", () => {
      if (this.$refs.tblPagoDePedidos) {
        this.$refs.tblPagoDePedidos.refresh();
      }
      let url = `${process.env.VUE_APP_API_URL}/pago-de-pedidos?pagos_codigo_consejera_no_coincide=0&$limit=4&PAGO_VERIFICACION_STATUS=0&CAMPAING_ID=${this.CAMPAING_ID}`;
      return this.$http
        .get(url, {
          params: {},
          headers: {
            Authorization:
              "Bearer  " + window.localStorage.getItem("feathers-jwt"),
          },
        })
        .then((r) => {
          console.log(r.data.data);
          this.$store.state.conteoPagoBoletasCodConsejeraNoCoinciden =
            r.data.total;
          this.$store.state.arregloPagoBoletasCodConsejeraNoCoinciden =
            r.data.data;
        });
    });

    /** actualizar verficiados y boletas que no coinciden cada vez que se genere un paogo */

    PagoDePedidos.on("patched", () => {
      if (this.$refs.tblPagoDePedidos) {
        this.$refs.tblPagoDePedidos.refresh();
      }
      let url = `${process.env.VUE_APP_API_URL}/pago-de-pedidos?pagos_codigo_consejera_no_coincide=0&$limit=4&PAGO_VERIFICACION_STATUS=0&CAMPAING_ID=${this.CAMPAING_ID}`;
      return this.$http
        .get(url, {
          params: {},
          headers: {
            Authorization:
              "Bearer  " + window.localStorage.getItem("feathers-jwt"),
          },
        })
        .then((r) => {
          console.log(r.data.data);
          this.$store.state.conteoPagoBoletasCodConsejeraNoCoinciden =
            r.data.total;
          this.$store.state.arregloPagoBoletasCodConsejeraNoCoinciden =
            r.data.data;
        });
    });
    /** */
    this.$watch(
      () => {
        return this.$refs.tblPagoDePedidos.query.MANIFEST_DB_ID;
      },
      (val) => {
        console.log("val", val);
        console.log("total data", this.$refs.tblPagoDePedidos.count);
        this.$emit("emit_tblFiltro_boletasPorManifiesto", val);
      }
    );
    /** escuchar cambios en los filtros de la tabla, para generar mismos registros en el excel */
    this.$watch(
      () => {
        return this.$refs.tblPagoDePedidos.query.id;
      },
      (val) => {
        this.$emit("emit_change_filtro_id", val);
      }
    );
    this.$watch(
      () => {
        // prettier-ignore
        return this.$refs.tblPagoDePedidos.query.MANIFEST_DB_ID;
      },
      (val) => {
        this.$emit("emit_change_filtro_MANIFEST_DB_ID", val);
      }
    );
    this.$watch(
      () => {
        // prettier-ignore
        return this.$refs.tblPagoDePedidos.query.ERP_DOC;
      },
      (val) => {
        this.$emit("emit_change_filtro_ERP_DOC", val);
      }
    );
    this.$watch(
      () => {
        // prettier-ignore
        return this.$refs.tblPagoDePedidos.query.DELIVERY_CLIENT_NAME;
      },
      (val) => {
        this.$emit("emit_change_filtro_DELIVERY_CLIENT_NAME", val);
      }
    );
    this.$watch(
      () => {
        // prettier-ignore
        return this.$refs.tblPagoDePedidos.query.documentos;
      },
      (val) => {
        this.$emit("emit_change_filtro_documentos", val);
      }
    );
    this.$watch(
      () => {
        // prettier-ignore
        return this.$refs.tblPagoDePedidos.query.PAGO_METODO_DETALLE;
      },
      (val) => {
        this.$emit("emit_change_filtro_PAGO_METODO_DETALLE", val);
      }
    );
    /**
     *
     * @param {manifiesto} manifiesto contiene toda la información
     * del manifiesto delc ual se desea ver su detalle en pagos
     */
    // solicitando desde vista de manifiestos
    // mostrar detalle de pagos
    this.$watch(
      () => {
        // prettier-ignore
        return this.prop_manifiesto;
      },
      (val) => {
        console.log(this.prop_manifiesto);
        let manifesto_filtro = document.querySelector(
          "#tblPagoDePedidos > div.table-responsive > table > thead > tr.VueTables__filters-row > th:nth-child(2) > div > input"
        );

        manifesto_filtro.select(); // you can also use input.focus()
        manifesto_filtro.value = val.id;

        this.$refs.tblPagoDePedidos.query.MANIFEST_DB_ID = val.id;

        this.$refs.tblPagoDePedidos.refresh();
      }
    );
  },
};
</script>

<style>
@media only screen and (max-width: 1366px) {
  /* For mobile phones: */
  body #tblPagoDePedidos > div.table-responsive > table > tbody > tr > td {
    font-size: 8.88px !important;
  }
}
body #tblPagoDePedidos > div.table-responsive > table > tbody > tr > td {
  font-size: 10.01px !important;
  text-align: left;
}

body
  #tblPagoDePedidos
  > div.table-responsive
  > table
  > tbody
  > tr
  > td:nth-child(1) {
  width: 57px;
}
body
  #tblPagoDePedidos
  > div.table-responsive
  > table
  > tbody
  > tr
  > td:nth-child(2) {
  width: 70px !important;
}
body
  #tblPagoDePedidos
  > div.table-responsive
  > table
  > tbody
  > tr
  > td:nth-child(3) {
  width: 80px !important;
}
body
  #tblPagoDePedidos
  > div.table-responsive
  > table
  > tbody
  > tr
  > td:nth-child(4) {
  width: 322px !important;
}
/* (1366x768) WXGA Display */
@media screen and (max-width: 1366px) {
  /* insert styles here */
  body
    #tblPagoDePedidos
    > div.table-responsive
    > table
    > tbody
    > tr
    > td:nth-child(4) {
    width: 233px !important;
  }
  body
    #tblPagoDePedidos
    > div.table-responsive
    > table
    > tbody
    > tr
    > td:nth-child(5) {
    width: 134px !important;
  }
  .btn-sm {
    font-size: 11px;
  }
}
/* columna de precio a favor, cabal o deudor */
body
  #tblPagoDePedidos
  > div.table-responsive
  > table
  > tbody
  > tr
  > td:nth-child(9) {
  width: 120px !important;
}
#tblPagoDePedidos
  > div.table-responsive
  > table
  > thead
  > tr.VueTables__filters-row
  > th:nth-child(1) {
  padding: 5px 2px !important;
}
</style>
